//
// Stepper Pills
//

// Base
.stepper.stepper-pills {
  $pills-size: 40px;
  $line-height: 2px;

  // Nav
  .stepper-nav {
    display: flex;
  }

  // Item
  .stepper-item {
    display: flex;
    align-items: center;
    transition: $transition-link;
    position: relative;

    //&:not(:last-child):after { // Target all but the last item for the connecting line
    //  content: ''; // Necessary for pseudo-elements
    //  position: absolute;
    //  height: $line-height; // Line thickness
    //  background-color: black; // Line color
    //  width: calc(100% - #{$pills-size}); // Span width minus icon size
    //  top: calc(#{$pills-size} / 2 - #{$line-height} / 2); // Vertically center
    //  left: $pills-size; // Start from the right edge of the icon
    //  z-index: 1; // Ensure it's below the icons
    //}

    // Icon
    .stepper-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $transition-link;
      width: $pills-size;
      height: $pills-size;
      border-radius: $border-radius;
      background-color: $gray-600;
      position: relative;
      margin-right: 1.5rem;
      z-index: 2;

      .stepper-check {
        display: none;
        font-size: 1rem;
      }

      .stepper-number {
        font-weight: $font-weight-bolder;
        color: $white !important;
        font-size: 1.25rem;
      }
    }

    // Label
    .stepper-label {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .stepper-title {
        color: $gray-400;
        font-weight: 600;
        font-size: 1.25rem;
        margin-bottom: 0.3rem;
      }

      .custom-stepper-title {
        font-size: 1.1rem;
      }

      .stepper-desc {
        color: $text-muted;
      }
    }

    // Current
    &.current {
      transition: $transition-link;

      .stepper-icon {
        transition: $transition-link;
        background-color: $danger;

        .stepper-check {
          color: $primary;
          display: none;
        }

        .stepper-number {
          color: $white !important;
          font-size: 1.35rem;
        }
      }

      .stepper-label {
        .stepper-title {
          color: $gray-800;
        }

        .stepper-desc {
          color: $gray-400;
        }
      }
    }

    // Completed
    &.current:last-child {
      .stepper-icon {
        transition: $transition-link;
        background-color: $danger;

        .stepper-check {
          display: none;
        }

        .stepper-number {
          color: $white !important;
          display: inline-block;

        }
      }

      .stepper-label {
        .stepper-title {
          color: $gray-800;
        }

        .stepper-desc {
          color: $gray-400;
        }
      }
    }

    &.completed {
      .stepper-icon {
        transition: $transition-link;
        background-color: $danger;

        .stepper-check {
          color: $white !important;
          display: inline-block;
        }

        .stepper-number {
          display: none;
        }
      }

      .stepper-label {
        .stepper-title {
          color: $gray-800;
        }

        .stepper-desc {
          color: $gray-400;
        }
      }
    }
  }

  // Column
  &.stepper-column {
    // Nav
    .stepper-nav {
      flex-direction: column;
    }

    // Item
    .stepper-item {
      position: relative;
      padding: 0;
      margin: 0;
      padding-bottom: 2.5rem;
    }

    // Icon
    .stepper-icon {
      z-index: 1;
    }

    // Line
    .stepper-line {
      display: block;
      content: ' ';
      justify-content: center;
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      bottom: 0;
      width: $pills-size;
      transform: translate(50%);
      border-left-width: 1px;
      border-left-style: dashed;
      border-left-color: $gray-300;
    }

    // First item
    .stepper-item:first-child {
      .stepper-line {
        top: 50%;
      }
    }

    // Last item
    .stepper-item:last-child {
      .stepper-line {
        bottom: 50%;
      }
    }
  }

}
