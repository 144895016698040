//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

.text-rich{
  white-space: pre-wrap;
}

.timeline-item > .timeline-label{
  width: 150px !important;
  text-align: right;
  padding-right: 10px;
}

.timeline-label:before{
  left: 151px !important;
}